import React, { useState, useEffect } from 'react';
import './CountdownTimer.css'; // Import the CSS for the timer

// Function to calculate time left until midnight
const calculateTimeLeft = () => {
    const now = new Date();
    const resetTime = new Date();
    resetTime.setHours(23, 59, 59, 999); // Reset at midnight

    const difference = resetTime - now;
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { hours, minutes, seconds };
};

const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // Update the timer every second
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Cleanup the interval on unmount
    }, []);

    return (
        <div className="countdown-timer">
            <h2 className="countdown-timer-text">Limited-Time Offer - Save Over 50% Today!</h2>
            <div className="timer-inline">
                <div className="timer-box">
                    <span className="timer-value">{timeLeft.hours.toString().padStart(2, '0')}</span>
                    <div className="smalltext-countdown">HOURS</div>
                </div>
                <div className="timer-box">
                    <span className="timer-value">{timeLeft.minutes.toString().padStart(2, '0')}</span>
                    <div className="smalltext-countdown">MINUTES</div>
                </div>
                <div className="timer-box">
                    <span className="timer-value">{timeLeft.seconds.toString().padStart(2, '0')}</span>
                    <div className="smalltext-countdown">SECONDS</div>
                </div>
            </div>
        </div>
    );
};

export default CountdownTimer;
