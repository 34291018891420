import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
//import { Gradient } from 'whatamesh';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import './Pricing.css';
import CountdownTimer from './CountdownTimer';


const modulesDataPricing = [
    {
        title: 'Introduction to Python',
        description: 'Essential for understanding the basics and setting up the environment. This module provides an overview of Python syntax and best practices for setup, including installing Python and setting up an IDE.',
        lessonCount: 1,
        quizCount: 1,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Basic Concepts and Operations',
        description: 'Fundamental building blocks of Python programming, including understanding different data types and basic operations. Learn to write simple Python scripts using basic data types like integers, floats, and strings.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Control Flow',
        description: 'Critical for writing programs that can make decisions (conditional statements) and perform repetitive tasks (loops). This module delves into the use of if-else statements, for and while loops, teaching you how to structure and manage the execution of code effectively in Python.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 6
    },
    {
        title: 'Functions',
        description: 'Understanding functions is key for writing organized and reusable code. This module covers how to define and call functions, parameter passing, return values, and basic scope rules.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 6
    },
    {
        title: 'Data Collections',
        description: 'Lists, tuples, and dictionaries are fundamental data structures in Python, used to store and manipulate data. Learn how to create, access, and manipulate these collections to effectively handle data.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Strings and Their Manipulations',
        description: 'Strings are a fundamental data type in Python, and knowing how to manipulate them is crucial. Explore string operations, formatting, and common methods to process textual data.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Basic File Handling',
        description: 'Reading from and writing to files is a common requirement in many Python programs. Learn to handle files with Python, including reading, writing, and understanding file handling modes.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Error and Exception Handling',
        description: 'Essential for writing robust programs that can handle unexpected errors. This module introduces try-except blocks and handling multiple exceptions for better error management.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Advanced Data Collections',
        description: 'Expanding knowledge on data structures, which is important for more complex data manipulation. Delve deeper into sets, frozen sets, and how to utilize advanced data structures.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Modules and Packages',
        description: 'Understanding modules and packages is crucial for leveraging existing code and organizing larger projects. Learn how to create modular code, use standard modules, and understand the concept of packages for code organization.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Intermediate File Handling',
        description: 'Introduces handling more complex file formats which are common in real-world applications. Gain skills in working with binary files, JSON, XML, and other common data formats in Python.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Object-Oriented Programming (OOP)',
        description: 'A key programming paradigm in Python that helps in managing larger codebases more effectively. Learn about classes, objects, inheritance, polymorphism, and encapsulation in Python.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Functional Programming Concepts',
        description: 'Provides an alternative approach to problem-solving and complements OOP. Understand the principles of functional programming, including lambda functions, map, filter, and reduce.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 5
    },
    {
        title: 'Python Standard Library Exploration',
        description: 'The vast standard library is one of Python’s greatest strengths, offering a wide range of functionalities. Explore various modules in the standard library to enhance your Python applications.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    },
    {
        title: 'Algorithms and Data Structures',
        description: 'Fundamental for understanding how to store data efficiently and solve problems effectively. Delve into classic algorithms, data structure design, and algorithmic problem-solving techniques.',
        lessonCount: 1,
        quizCount: 3,
        handsOnQuestionsCount: 3
    }
];

const Pricing = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const ModuleSummaryItemPricing = ({ module, index }) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleModule = () => setIsOpen(!isOpen);

        return (
            <div className={`module ${isOpen ? 'open' : ''}`} onClick={toggleModule}>
                <div className="module-title">
                    {index + 1}. {module.title}
                    <i className={`caret fa ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`} />
                </div>
                {isOpen && (
                    <div className="module-description">
                        <div>{module.description}</div><br />
                        <div><strong>Included in this module:</strong></div><br />
                        <div className="module-extra-info">
                            <p>- Lessons: {module.lessonCount}</p>
                            <p>- Quizzes: {module.quizCount}</p>
                            <p>- Hands-On Coding Questions: {module.handsOnQuestionsCount}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    };


    useEffect(() => {
        // Redirect paying subscribers to the dashboard
        if (user && user.stripeSubscriptionId) {
            navigate('/dashboard');
        }
    }, [user, navigate]); // Dependency array includes user and navigate

/*
    useEffect(() => {
        const gradient = new Gradient();
        gradient.initGradient("#gradient-canvas-pricing");
    });*/

    // Define initiateStripeCheckout function
    const initiateStripeCheckout = async () => {
        try {
            //const response = await axios.post('http://localhost:4000/create-checkout-session');
            //const response = await axios.post('http://localhost:4000/create-checkout-session', {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/create-checkout-session`, {
            googleId: user.googleId // Assuming 'user' has a 'googleId' attribute
        });
            const session = response.data;
            const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            const { error } = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
            if (error) {
                console.log(error);
            }
        } catch (error) {
            console.error('Stripe checkout error:', error);
        }
    };

useEffect(() => {
    console.log('Location State on Pricing page:', location.state);
    if (user && location.state?.action === 'subscribe') {
        initiateStripeCheckout();
    }
}, [user, location.state]);


    const handleCheckout = async () => {
        if (!user) {
            navigate('/login', { state: { from: { pathname: '/pricing' }, action: 'subscribe' } });
            return;
        }
        
        initiateStripeCheckout();
    };

/* works fine
    const handleCheckout = async () => {
        if (!user) {
            navigate('/login', { state: { from: { pathname: '/pricing' }, action: 'subscribe' } });
            return;
        }
        
        try {
            const response = await axios.post('http://localhost:4000/create-checkout-session');
            const session = response.data;
            const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY); // Replace with your publishable key
            const { error } = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
            if (error) {
                console.log(error);
            }
        } catch (error) {
            console.error(error);
        }
    };*/



    return (
        <div className="pricing-background">
            <Helmet>
                <title>Pricing | Reventt</title>
                <meta
                    name="description"
                    content="Get a subscription on reventt.com to access a comprehensive Python coding curriculum, including hands-on exercises and quizzes. Start your coding journey today!"
                />
            </Helmet>
            {/*<canvas id="gradient-canvas-pricing" className="canvas-style"></canvas>*/}
            
            <div className="cards-container">
                {/* Pricing Card */}
                <div className="pricing-card">

                    <CountdownTimer />

                    <h2 className="plan-name">The Ultimate Learn to Code Curriculum</h2>
                    <div className="prices">
                        <div className="current-price-container">
                            <p className="plan-price">$489</p>
                            <p className="original-price" style={{ textDecoration: 'line-through' }}>$999</p>
                        </div>
                        <p className="access-duration">1 Year Access</p>
                    </div>
                    <p className="plan-description">Unlock our extensive Python coding curriculum, your fastest path to becoming a programmer.</p>
                    <ul className="plan-features">
                        <li>15 Detailed Modules Covering Essential Python Concepts</li>
                        <li>Exclusive Access to Expert-Created Modules Proven to Fast-Track Your Coding Skills</li>
                        <li>Hands-On Coding Exercises with Solution Insights</li>
                        <li>Module-Specific Quizzes for Knowledge Assessment</li>
                        <li>Streamlined Learning Path for Effortless Coding Expertise</li>
                    </ul>
                    <p className="pricing-notice">No auto-renewal - Pay once for a full year of unlimited access!</p>
                    <button className="subscribe-button" onClick={handleCheckout}>Buy Now</button>
                </div>

                {/* Key Benefits Section */}
                {/*
                <div className="key-benefits">
                    <h3 className="benefits-title">Why Choose Our Coding Platform?</h3>
                    <ul className="benefits-list">
                        <li>In-depth Python Modules for Comprehensive Understanding.</li>
                        <li>Hands-On Exercises for Practical Coding Experience.</li>
                        <li>Immediate Solution Feedback for Enhanced Learning.</li>
                        <li>Efficient and Clear Progression Towards Coding Proficiency.</li>
                    </ul>
                </div>*/}

                <div className="modules-summary-pricing">
                    <h3 className="modules-title-pricing">Curriculum Overview</h3>
                    <div className="modules-list-pricing">
                        {modulesDataPricing.map((module, index) => (
                            <ModuleSummaryItemPricing key={index} module={module} index={index} />
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Pricing;
